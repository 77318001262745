export interface IFormCallMasterModel {
  name: string | number | Date | null;
  phone: string | number | Date | null;
  captcha?: string | null;
  captcha_key?: string | null;
}

/**
 * @description CallMaster form model
 */
export class FormCallMasterModel {
  public name: IFormCallMasterModel['name'];
  public phone: IFormCallMasterModel['phone'];
  public captcha: IFormCallMasterModel['captcha'];
  public captcha_key: IFormCallMasterModel['captcha_key'];

  constructor(attrs: Partial<IFormCallMasterModel> | null = {}, enableCaptcha: boolean = false) {
    attrs = attrs ?? {};

    this.name = attrs.name || null;
    this.phone = attrs.phone || null;

    if (enableCaptcha) {
      this.captcha = attrs.captcha || null;
      this.captcha_key = attrs.captcha_key || null;
    }
  }

  public get captchaEnabled() {
    return this.captcha;
  }

  public set captchaEnabled(captcha: IFormCallMasterModel['captcha']) {
    this.captcha = captcha;
  }

  public reset() {
    this.name = null;
    this.phone = null;
    this.captcha = this.captchaEnabled ? null : undefined;
    this.captcha_key = this.captchaEnabled ? null : undefined;
  }

  public toJSON(): IFormCallMasterModel {
    return {
      name: this.name,
      phone: this.phone,
      captcha: this.captchaEnabled ? this.captcha : undefined,
      captcha_key: this.captchaEnabled ? this.captcha_key : undefined,
    };
  }
}
